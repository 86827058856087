import http from '../clients/Axios'
export default class ReportService {
  async exportExpatTimesheetSummaryPeriod(timesheet_schedule, period) {
    const params = new URLSearchParams()

    // params.append('filter[nationality]', 'expat')

    if (timesheet_schedule) {
      params.append('filter[timesheet_schedule]', timesheet_schedule)
    }

    if (period) {
      params.append('filter[period]', period)
    }

    return http({
      url: `/timesheet/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportExpatTimesheetSummaryRangePeriod(timesheet_from, timesheet_to) {
    const params = new URLSearchParams()

    // params.append('filter[nationality]', 'expat')

    if (timesheet_from) {
      params.append('filter[timesheet_from]', timesheet_from)
    }

    if (timesheet_to) {
      params.append('filter[timesheet_to]', timesheet_to)
    }

    return http({
      url: `/timesheet/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportNationalTimesheetSummaryPeriod(timesheet_schedule) {
    const params = new URLSearchParams()

    params.append('filter[nationality]', 'national')

    if (timesheet_schedule) {
      params.append('filter[timesheet_schedule]', timesheet_schedule)
    }

    return http({
      url: `/timesheet/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportNationalTimesheetSummaryRangePeriod(
    timesheet_from,
    timesheet_to
  ) {
    const params = new URLSearchParams()

    params.append('filter[nationality]', 'national')

    if (timesheet_from) {
      params.append('filter[timesheet_from]', timesheet_from)
    }

    if (timesheet_to) {
      params.append('filter[timesheet_to]', timesheet_to)
    }

    return http({
      url: `/timesheet/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportPayrollSummary(payroll_schedule) {
    const params = new URLSearchParams()

    if (payroll_schedule) {
      params.append('filter[payroll_schedule]', payroll_schedule)
    }

    return http({
      url: `/payroll/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }

  async exportOTCalculation(overtime_schedule) {
    const params = new URLSearchParams()

    if (overtime_schedule) {
      params.append('filter[overtime_schedule]', overtime_schedule)
    }

    return http({
      url: `/overtime/export`,
      method: 'GET',
      params: params,
      responseType: 'blob',
    })
  }
}
